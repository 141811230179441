<template>
    <div class="view-account">
        <Main v-show="!changeComponent.show" @click-card="showComponent" />
        <ChangeContainer
            :visible.sync="changeComponent.show"
            :type="changeComponent.type"
        />
    </div>
</template>
<script>
import Main from "./main.vue";
import ChangeContainer from "./changeContainer.vue";
export default {
    name: "Account",
    components: {
        Main,
        ChangeContainer
    },
    data() {
        return {
            changeComponent: {
                show: false,
                type: ""
            }
        };
    },
    methods: {
        showComponent(component) {
            this.changeComponent.show = true;
            this.changeComponent.type = component;
        },
        refreshUserInfo() {
            this.$store.dispatch("GetUserInfo");
        }
    },
    mounted() {
        this.refreshUserInfo();
    },
    updated() {
        this.refreshUserInfo();
    }
};
</script>
<style lang="scss" scoped></style>
