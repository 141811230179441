<template>
    <div class="account-main">
        <div class="avatar-container">
            <el-avatar
                class="avatar"
                :size="80"
                @click.native="showChangeAvatar"
            >
                <img v-if="avatar" :src="avatar" style="object-fit: cover;" />
                <span v-else>{{ userInfo.username }}</span>
                <div class="change-avatar-bg">
                    <div>修改</div>
                    <div>头像</div>
                </div>
            </el-avatar>
            <p>{{ userInfo.username }}</p>
        </div>
        <BorderContainer
            class="password-container"
            icon="el-icon-key"
            title="登录密码"
            itip="success"
            describe="登录密码是您账号的登录凭证，您可以使用密码登录系统"
            @click.native="showComponent('Password')"
        />
        <BorderContainer
            class="email-container"
            icon="el-icon-message"
            title="绑定邮箱"
            :itip="userInfo.email ? 'success' : 'info'"
            describe="邮箱是您身份的重要凭证。当帐号发生异常时，将会通过该邮箱第一时间通知到您"
            @click.native="showComponent('Email')"
        />
        <BorderContainer
            v-if="hasPermissions('bind_phone')"
            class="phone-container"
            icon="el-icon-mobile-phone"
            title="绑定手机"
            :itip="userInfo.phone ? 'success' : 'error'"
            describe="绑定手机号将作为您身份验证的重要方式，请谨慎操作！"
            @click.native="showComponent('Phone')"
        />
        <ChangeAvatar :visible.sync="changeAvatar.visible" />
    </div>
</template>
<script>
import ChangeAvatar from "./changeAvatar.vue";
import BorderContainer from "./borderContainer.vue";
import { mapGetters } from "vuex";

export default {
    name: "Account",
    components: {
        BorderContainer,
        ChangeAvatar
    },
    computed: {
        ...mapGetters(["userInfo", "tagCurrent"]),
        avatar() {
            return this.userInfo.avatar && this.userInfo.avatar.avatar;
        },
        permissions() {
            return Array.isArray(this.tagCurrent[0].meta.permissions)
                ? this.tagCurrent[0].meta.permissions
                : [];
        }
    },
    data() {
        return {
            changeAvatar: {
                visible: false
            }
        };
    },
    methods: {
        showComponent(component) {
            this.$emit("click-card", component);
        },
        showChangeAvatar() {
            this.changeAvatar.visible = true;
        },
        hasPermissions(key) {
            return this.permissions.indexOf(key) >= 0;
        }
    }
};
</script>
<style lang="scss" scoped>
.account-main {
    .avatar-container {
        text-align: center;
        margin-bottom: 20px;

        .avatar {
            cursor: pointer;
            user-select: none;

            .change-avatar-bg {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.5);
                font-size: 14px;
                line-height: 14px;
                padding: 11px;
                box-sizing: border-box;
                transition: 0.2s;
            }

            &:hover {
                .change-avatar-bg {
                    transform: translateY(-100%);
                }
            }
        }
    }
}
</style>
